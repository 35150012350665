<!-- 成绩复核列表 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";

.pay-box img {
  width: 56px;
  height: 56px;
}
td .btn {
  min-width: 60px;
  margin-right: 10px;
}
td .btn:last-child {
  margin-right: unset;
}
label {
  margin-bottom: 0;
  color: #8c8c8c;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  getSendList,
  payTpye,
  payOrder,
  deleteSend,
  updateYjzt,
} from "@/api/grade";
import { getjfzt } from "@/api/examination.js";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "证书邮寄列表",
      items: [
        {
          text: "证书邮寄",
        },
        {
          text: "邮寄记录",
          active: true,
        },
      ],
      defaultvalue: 3,
      currentpage: 3,
      sendRecord: [],
      zfData: {
        zffs: "",
        sffssz: "",
        data: {},
      },
      zfModal: false,
      chooseIndex: "",
      interval: null,
      showCertListModal:false,
      certInfo:{}
    };
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    quickPay() {
      if (!this.zfData.zffs) {
        this.$message("请选择支付方式");
        return;
      }
      let _this = this;
      let obj = {
        sid: this.sendRecord[this.chooseIndex].sid,
        zffs: this.zfData.zffs,
      };
      payOrder(obj).then((res) => {
        if (res.status) {
          _this.zfData.data = res.data;
          if (_this.zfData.zffs == "02") {
            const div = document.createElement("div");
            div.setAttribute("id", "zfbForm");
            div.innerHTML = res.data.result;
            document.body.insertBefore(div, document.body.children[0]);
            // document.forms[0].setAttribute("target", "_blank");
            document.forms[0].submit();
          } else if (_this.zfData.zffs == "01") {
            _this.zfModal = true;
          } else if (_this.zfData.zffs == "07"){
            let url = res.data.result;
            let a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("target", "_blank");

            document.body.appendChild(a);
            a.click();
          }else {
            let url = res.data.result;
            let a = document.createElement("a");
            a.setAttribute("href", url);
            // a.setAttribute("target", "_blank");

            document.body.appendChild(a);
            a.click();
          }

          _this.$confirm("我已支付成功", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            getjfzt({
              outTradeNo: res.data.trade.ddh,
              zffs: _this.zfData.zffs,
            }).then((res) => {
              if (res.status) {
                if (res.data.tradeStatus) {
                  _this.$bvModal.hide("derateModal");
                  _this.zfModal = false;
                  clearInterval(_this.interval);
                  _this.interval = null;
                  _this.sendList();
                }
              }
            });
          })
          .catch(()=> {
            getjfzt({
              outTradeNo: res.data.trade.ddh,
              zffs: _this.zfData.zffs,
            }).then((res) => {
              if (res.status) {
                if (res.data.tradeStatus) {
                    _this.$bvModal.hide("derateModal");
                    _this.zfModal = false;
                    clearInterval(_this.interval);
                    _this.interval = null;
                    _this.sendList();
                }
              }
            });
          });
          // if (_this.interval) {
          //   clearInterval(_this.interval);
          //   _this.interval = null;
          // }
          // _this.interval = setInterval(function () {
          //   getjfzt({
          //     outTradeNo: "" + res.data.trade.ddh,
          //     zffs: _this.zfData.zffs,
          //   }).then((res) => {
          //     if (res.status) {
          //       if (res.data.tradeStatus) {
          //         _this.$bvModal.hide("derateModal");
          //         _this.zfModal = false;
          //         clearInterval(_this.interval);
          //         _this.interval = null;
          //         _this.sendList();
          //       }
          //     }
          //   });
          // }, 3000);
        }
      });
    },
    repeal(index) {
      let _this = this
      let sid = this.sendRecord[index].sid;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSend(sid).then((res) => {
            if (res.status) {
              _this.$message({
                message: "删除成功",
                type: "success",
              });
              _this.sendList();
            }
          });
        })
        .catch(() => {});
    },
    pay(index) {
      let sid = this.sendRecord[index].sid;
      this.chooseIndex = index
      console.log(sid);
    },
    info(index) {
      this.certInfo = this.sendRecord[index]
      this.showCertListModal = true;
    },
    signFor(index) {
      let _this = this
      let sid = this.sendRecord[index].sid;
      this.$confirm("确认已签收邮件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateYjzt(sid).then((res) => {
            if (res.status) {
              _this.sendRecord[index].yjzt == 2;
            }
          });
        })
        .catch(() => {});
    },
    sendList() {
      getSendList().then((res) => {
        if (res.status) {
          this.sendRecord = res.data;
        }
      });
    },
  },
  created() {
    this.sendList();
    payTpye().then((res) => {
      if (res.status) {
        this.zfData.sffssz = res.data;
      }
    });
  },
  watch: {
    zfModal: {
      handler(val) {
        if (val == false) {
          clearInterval(this.interval);
          this.interval = "";
        }
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash flexList">
              <span class="mr-2">以下是为您已提交的证书邮寄列表</span>
              <a href="/certificate"
                ><button type="button" class="btn btn-info h30">
                  <i class="iconfont icon-plus-circle mr-2 font-size-18"></i
                  >提交邮寄申请
                </button></a
              >
            </h4>
            <div class="table-responsive border">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th>证书名称</th>
                    <th>邮寄地址</th>
                    <th style="width: 5%">费用(元)</th>
                    <th style="width: 8%">缴费状态</th>
                    <th style="width: 8%">邮寄状态</th>
                    <th style="width: 10%">快递单号</th>
                    <th style="width: 10%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in sendRecord" :key="index">
                    <th scope="row">1</th>
                    <td>
                      <a href="javascript:void(0);" @click="info(index)">{{ item.zsmc }}</a>
<!--                      <div>{{ item.zsh }}</div>-->
                    </td>
                    <td>
                      {{ item.ssmc }}/{{ item.djsmc }}/{{ item.qxmc }}/{{
                        item.dz
                      }}
                      {{ item.xm }} {{ item.lxdh }}
                    </td>
                    <td>{{ item.zfje / 100 }}</td>
                    <td>
                      {{
                        item.zfzt == 0
                          ? "待缴费"
                          : item.zfzt == 1
                          ? "已缴费"
                          : "已退款"
                      }}
                    </td>
                    <td>
                      {{
                        item.yjzt == -1
                          ? "未支付不邮寄"
                          : item.yjzt == 0
                          ? "已支付未邮寄"
                          : item.yjzt == 1
                          ? "已邮寄"
                          : item.yjzt == 2
                          ? "已签收"
                          : "已退件"
                      }}
                    </td>
                    <td>
                      <div class="blue-font" @click="info(index)">
                        {{ item.wlgs }} {{ item.ydh }}
                        <!-- {{ item.wldh ? "追踪状态" : "" }} -->
                      </div>
                    </td>
                    <!-- <td v-if="item.zfzt == 1" style="padding: 5px:">
                      <button
                        type="button"
                        class="btn btn-info h30 mr-2"
                        @click="info(index)"
                      >
                        详情
                      </button>
                    </td> -->
                    <td style="padding: 5px:">
                      <button
                        v-if="item.zfzt == 0"
                        type="button"
                        class="btn btn-info h30 mr-2"
                        @click="pay(index)"
                        v-b-modal.derateModal
                      >
                        缴费
                      </button>
                      <button
                        v-if="item.zfzt != 1"
                        type="button"
                        class="btn btn-danger h30"
                        @click="repeal(index)"
                      >
                        删除
                      </button>
                      <button
                        v-if="item.yjzt == 1"
                        type="button"
                        class="btn btn-danger h30"
                        @click="signFor(index)"
                      >
                        签收
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="derateModal"
        ref="derateModal"
        title="缴费方式选择"
        title-class="font-18"
        centered
        class="person-modal"
        hide-footer
        size="lg"
      >
        <div class="card">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">请选择支付方式</h4>
            <div class="pay-container p-3">
              <div class="mb-3 d-flex justify-content-between">
                <div
                  class="pay-box flexList justify-content-center"
                  :class="{ active: zfData.zffs == '01' }"
                  v-if="zfData.sffssz[0] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      value="01"
                      class="custom-control-input"
                      v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio1"
                      >微信支付</label
                    >
                  </div>
                  <img
                    src="@/assets/images/person/antOutline-wechat.png"
                    alt=""
                  />
                </div>
                <div
                  class="pay-box flexList justify-content-center"
                  :class="{ active: zfData.zffs == '02' }"
                  v-if="zfData.sffssz[1] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      value="02"
                      class="custom-control-input"
                      v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio2"
                      >支付宝</label
                    >
                  </div>
                  <img
                    src="@/assets/images/person/antOutline-alipay-circle.png"
                    alt=""
                  />
                </div>
<!--                <div-->
<!--                  class="pay-box flexList justify-content-center"-->
<!--                  :class="{ active: zfData.zffs == '03' }"-->
<!--                  v-if="zfData.sffssz[2] == 1"-->
<!--                >-->
<!--                  <div class="custom-control custom-radio mr-3">-->
<!--                    <input-->
<!--                      type="radio"-->
<!--                      id="customRadio3"-->
<!--                      name="customRadio"-->
<!--                      value="03"-->
<!--                      class="custom-control-input"-->
<!--                      v-model="zfData.zffs"-->
<!--                    />-->
<!--                    <label class="custom-control-label" for="customRadio3"-->
<!--                      >易宝</label-->
<!--                    >-->
<!--                  </div>-->
<!--                  <img src="@/assets/images/person/csc-logo.png" alt="" />-->
<!--                </div>-->

                <div
                    class="pay-box flexList justify-content-center"
                    :class="{ active: zfData.zffs == '07' }"
                    v-if="zfData.sffssz[3] == 1"
                >
                  <div class="custom-control custom-radio mr-3">
                    <input
                        type="radio"
                        id="customRadio4"
                        name="customRadio"
                        value="07"
                        class="custom-control-input"
                        v-model="zfData.zffs"
                    />
                    <label class="custom-control-label" for="customRadio4"
                    >合利宝</label
                    >
                  </div>
                  <img src="@/assets/images/person/helibao.png" alt="" style="height: 30px;width: auto" />
                </div>
              </div>
              <button
                type="button"
                class="btn btn-info pay-btn"
                @click="quickPay"
              >
                立即支付
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="zfModal"
        v-model="zfModal"
        centered
        :title="zfData.zffs == '02' ? '支付宝支付' : '微信支付'"
        size="lg"
        title-class="font-18"
        hide-footer
        style="min-height: 300px"
        class="d-flex justify-content-center align-items-center"
      >
        <div v-if="zfModal">
          <div
            v-if="zfData.zffs == '01'"
            class="wxzf d-flex flex-column align-items-center justify-content-center"
            style="min-height: 300px"
          >
            <div>缴费项目：{{ zfData.data.trade.ddmc }}</div>
            <div>收费单位：{{ zfData.data.trade.shmc }}</div>
            <div class="text-warning">
              缴费金额：{{ zfData.data.trade.ddzje / 100 }}元
            </div>
            <img
              :src="zfData.data.result"
              alt=""
              v-if="zfData.zffs == '01'"
              style="width: 150px"
            />
            <div>微信扫描支付</div>
          </div>
        </div>
      </b-modal>


      <!-- 弹窗开始 -->

      <b-modal id="certinfo" v-model="showCertListModal" title="证书详情" centered title-class="font-18" hide-footer>
        <div>
          <el-form>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>&#12288;证书号：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.zsh}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>证书名称：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.zsmc}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>&#12288;&#12288;级别：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.jb}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>&#12288;&#12288;专业：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.zy}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>邮寄地址：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.ssmc}} {{certInfo.djsmc}}  {{certInfo.qxmc}}
                  {{certInfo.dz}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>邮政编码：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.yb}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>联系电话：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.yddh}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>支付状态：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{
                    certInfo.zfzt == 0
                        ? "待缴费"
                        : certInfo.zfzt == 1
                            ? "已缴费"
                            : "已退款"
                  }}  {{ certInfo.zfje/100}} 元
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>支付订单：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.ddh}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>支付时间：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.zfsj}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>物流公司：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.wlgs}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>&#12288;运单号：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{certInfo.ydh}}
                </div>
              </div>
            </el-form-item>
            <el-form-item class="mb-0">
              <div class="d-flex check-distri "><label>邮寄状态：</label>
                <div class=" col-sm-10 p-0 flexList">
                  {{
                    certInfo.yjzt == -1
                        ? "未支付不邮寄"
                        : certInfo.yjzt == 0
                            ? "已支付未邮寄"
                            : certInfo.yjzt == 1
                                ? "已邮寄"
                                : certInfo.yjzt == 2
                                    ? "已签收"
                                    : "已退件"
                  }}
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </b-modal>
      <!-- 弹窗结束 -->
    </div>
  </Layout>
</template>
